import logo from "./logo.svg";
import "./css/bootstrap.css";
import "./css/font-awesome/css/all.css";
import "./css/main.css";

function App() {
  return (
    <div className="site-wrapper">
      <div className="site-wrapper-inner">
        <div className="cover-container">
          <div className="masthead clearfix">
            <div className="inner">
              <h3 className="masthead-brand">RUMA JAPARA</h3>
              <nav className="nav nav-masthead">
                <a className="nav-link nav-social" href="#" title="Facebook">
                  <i className="fab fa-facebook-f" aria-hidden="true" />
                </a>
                <a className="nav-link nav-social" href="#" title="Twitter">
                  <i className="fab fa-twitter" aria-hidden="true" />
                </a>
                <a className="nav-link nav-social" href="#" title="Youtube">
                  <i className="fab fa-youtube" aria-hidden="true" />
                </a>
                <a className="nav-link nav-social" href="#" title="Instagram">
                  <i className="fab fa-instagram" aria-hidden="true" />
                </a>
              </nav>
            </div>
          </div>
          <div className="inner cover">
            <h1 className="cover-heading">The adventure Begins</h1>
            <p className="lead cover-copy">
              Hold tight as we get our working robots together and produce the
              most astonishing product ever.
            </p>
          </div>
          <div className="mastfoot">
            <div className="inner">
              <p>
                © RUMA JAPARA
              </p>
            </div>
          </div>
          <div
            className="modal fade"
            id="subscribeModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="subscribeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="subscribeModalLabel">
                    Get Notified on Launch:
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="form-control-label"
                      >
                        Enter you e-mail to get notified when we launch
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                        placeholder="your-name@example.com"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
